import React from "react";
import styles from "./CreateArtist.module.scss";
import { useState, useContext } from "react";
import { Button } from "react-bootstrap";

import { useImageInput } from "../../hooks/useImageInput";
import axios from "axios";
import { ArtistForm } from "../../components/ArtistForm";
import { ContentHeader } from "../../components/ContentHeader";
import { ConfigContext } from "../../contexts/ConfigContext";
import { AuthContext } from "../../contexts/AuthContext";
import cssStyles from "./CreateArtist.module.scss";

const CreateArtist = () => {
    const thangs = useContext(ConfigContext);
    const versionsSorted = thangs.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    });

    const { authToken } = useContext(AuthContext);

    const { images, addImage, removeImage, updateImageUrl, updateImageVersion } = useImageInput(() => {
        return versionsSorted.map((version) => {
            return {
                url: "",
                version: version.id,
            };
        });
    });
    const [name, setName] = useState("");
    const [styles, setStyles] = useState([]);

    const updateStyles = (selectedStyles) => {
        setStyles(selectedStyles);
    };

    const saveArtist = () => {
        const styleIds = styles.map((style) => style.id);
        const reqBody = {
            name,
            images,
            styles: styleIds,
        };
        axios({
            url: "api/artists",
            data: reqBody,
            method: "post",
            headers: { Authorization: `Bearer ${authToken}` },
        })
            .then((res) => {
                alert("Artist has been saved");
            })
            .catch((e) => {});
    };

    return (
        <div className={`${cssStyles["CreateArtist"]}`}>
            <div className="page-header">
                <h4>Create Artist</h4>
            </div>
            <div className="page-content">
                <ArtistForm
                    images={images}
                    addImage={addImage}
                    removeImage={removeImage}
                    updateImageUrl={updateImageUrl}
                    updateImageVersion={updateImageVersion}
                    name={name}
                    setName={setName}
                    updateStyles={updateStyles}
                    styles={styles}
                />
                <div className="button-bar">
                    <Button
                        onClick={(event) => {
                            saveArtist();
                        }}>
                        Save Artist
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default CreateArtist;
