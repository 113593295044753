import { useState, useEffect } from "react";
import copy from "copy-to-clipboard";

export default function useCopyClipboard(defaultText, options) {

  const [isCopied, setIsCopied] = useState(false);
  const successDuration = options && options.successDuration;

  useEffect(() => {
    if (isCopied && successDuration) {
      const id = setTimeout(() => {
        setIsCopied(false);
      }, successDuration);

      return () => {
        clearTimeout(id);
      };
    }
  }, [isCopied, successDuration]);

  return [
    isCopied,
    (text) => {
      const textToCopy = text || defaultText;

      if (!textToCopy) {
        throw Error("Didn't provide text for `react-use-clipbaord` to copy.");
      }

      const didCopy = copy(textToCopy);
      setIsCopied(didCopy);
    },
  ];
}