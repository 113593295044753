import React from "react";
import styles from "./ConfigList.module.scss";
import { Button, Table } from "react-bootstrap";

const ConfigList = ({ setLoadedConfig, deleteConfig, configs }) => {
  const renderFiles = () => {
    return configs.map((config) => {
      const lastUpdateDate = new Date(config.lastUpdate).toLocaleString();
      return (
        <tr>
          <td>{config.name}</td>
          <td>{lastUpdateDate}</td>
          <td>
            <Button
              size="sm"
              variant="outline-dark"
              onClick={() => {
                setLoadedConfig(config.id);
              }}>
              Load
            </Button>{" "}
            <Button
              size="sm"
              variant="outline-dark"
              onClick={() => {
                deleteConfig(config.id);
              }}>
              Delete
            </Button>
          </td>
        </tr>
      );
    });
  };

  let content;

  if (configs.length < 1) {
    content = <p>You have no saved configurations. Save a configuration and it will appear here!</p>;
  } else {
    content = (
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Last Updated</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{renderFiles()}</tbody>
      </Table>
    );
  }

  return <div className={`${styles["ConfigList"]}`}>{content}</div>;
};

export default ConfigList;
