import logo from "./logo.svg";
import { Routes, Route, NavLink, HashRouter } from "react-router-dom";
import { useState, Context } from "react";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Nav } from "react-bootstrap";
import "./App.scss";
import Logo from "./images/logo3.svg";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home";
import PromptEditor from "./pages/PromptEditor";
import Login from "./pages/Login";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useFetch } from "./hooks/useFetch";
import { AuthContext } from "./contexts/AuthContext";
import { ConfigContext } from "./contexts/ConfigContext";
import ViewArtists from "./pages/ViewArtists";
import CreateArtist from "./pages/CreateArtist";
import EditArtist from "./pages/EditArtist";

import ViewStyles from "./pages/ViewStyles";
import CreateStyle from "./pages/CreateStyle";
import EditStyle from "./pages/EditStyle";

function App() {
    const [cookies, setCookie] = useCookies();
    const cookieToken = cookies["auth-token"];

    const [authToken, setAuthToken] = useState(cookieToken);
    const [user, setUser] = useState({});

    const { data, error, loading } = useFetch({
        url: "api/versions",
        method: "get",
        dependencies: [],
    });

    if (loading) {
        return <p>loading</p>;
    }

    const testToken = () => {
        axios({
            method: "get",
            url: "/beanbean",
            headers: { Authorization: `Bearer ${authToken}` },
        })
            .then((res) => {
                console.log(res);
                alert("WOOHOO!");
            })
            .catch((e) => {
                alert("FAIL!");
                console.log("ERROR", e);
            });
    };

    return (
        <HashRouter>
            <AuthContext.Provider value={{ authToken, setAuthToken, user, setUser }}>
                <ConfigContext.Provider value={data}>
                    <div className="App">
                        <div className="sidebar">
                            <header>
                                <div className="logo">
                                    <NavLink to="/">
                                        <img src={Logo} alt="PromptLab Logo" />
                                    </NavLink>
                                </div>
                            </header>
                            <Nav className="main-nav">
                                <NavLink to="/">Home</NavLink>
                                <hr />
                                <NavLink to="/prompt">Prompt Editor</NavLink>
                                <hr />
                                <NavLink to="/artists">Artist References</NavLink>
                                <NavLink to="/styles">Style References</NavLink>

                                {user.id && user.USER_ROLE_ADMIN && (
                                    <>
                                        <hr />
                                        <NavLink to="/artists/create">Create Artist</NavLink>
                                        <NavLink to="/styles/create">Create Style</NavLink>
                                    </>
                                )}

                                <hr />
                                {/* <Button className="mt-4"
                                    onClick={() => {
                                        testToken();
                                    }}>
                                    Test
                                </Button> */}
                            </Nav>
                        </div>
                        <div className="main-container">
                            <main>
                                <Routes>
                                    <Route path="/" element={<Home />} />
                                    <Route path="/prompt" element={<PromptEditor />} />
                                    <Route path="/login" element={<Login />} />
                                    <Route path="/styles" element={<ViewStyles />} />
                                    <Route path="/styles/:id" element={<EditStyle />} />
                                    <Route path="/styles/create" element={<CreateStyle />} />
                                    <Route path="/artists/:id" element={<EditArtist />} />
                                    <Route path="/artists/create/" element={<CreateArtist />} />
                                    <Route path="/artists" element={<ViewArtists />} />
                                </Routes>
                            </main>

                            <footer>
                                <Footer />
                            </footer>
                        </div>
                    </div>
                </ConfigContext.Provider>
            </AuthContext.Provider>
        </HashRouter>
    );
}

export default App;
