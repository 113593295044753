import { useRef, useEffect } from "react";
import axios from "axios";
import { useDrag, useDrop } from "react-dnd";
import { Button, Form, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { IconDeleteX, IconDrag, IconRandom } from "../Icons";
import styles from "./Token.module.scss";
import classnames from "classnames"

const Token = ({ id, inputType, index, moveCard, token, editMode, updateToken, tokensKey, handleRemove }) => {
  const ref = useRef(null);

  const getRandomValue = () => {
    if (inputType === "number") {
      updateToken(tokensKey, token.id, Math.floor(Math.random() * 1000));
      return;
    }

    const getShit = axios({
      method: "get",
      url: "https://random-word-api.herokuapp.com/word",
    }).then((res) => {
      updateToken(tokensKey, token.id, res.data);
    });
  };

  const [{ handlerId }, drop] = useDrop({
    accept: tokensKey,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!editMode) {
        return;
      }
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: tokensKey,
    item: () => {
      if (!editMode) {
        return;
      }
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const klassNames = classnames(
	  styles["param"],
	  styles["Token"]
  );

  return (
	
    <div className={klassNames} key={token.id} ref={ref} style={{ opacity }} data-handler-id={handlerId}>
      <IconDrag style={{ opacity: 0.4 }} />
      <label htmlFor={token.id}>{token.id}</label>
      <InputGroup>
        <Form.Control
          size="sm"
          type={inputType}
          id={token.id}
          onChange={(event) => {
            updateToken(tokensKey, token.id, event.currentTarget.value);
          }}
          value={token.value}
        />
        {editMode && (
          <OverlayTrigger overlay={<Tooltip style={{position:"fixed"}} id={`tooltip-top`}>Remove Token</Tooltip>}>
            <Button
              size="sm"
              variant="dark"
              onClick={() => {
                handleRemove(token.id);
              }}>
              <IconDeleteX />
            </Button>
          </OverlayTrigger>
        )}
      </InputGroup>
    </div>
  );
};

export default Token;
