import { useState, useRef } from "react";
import styles from "./PromptEditor.module.scss";
import { defaultSetting } from "../../configs/defaults";
import { blank } from "../../configs/blank";
import useClipboard from "../../hooks/useClipboard";
import TokenList from "../../components/TokenList";
import {
    OverlayTrigger,
    Tooltip,
    ToggleButton,
    ButtonGroup,
    Card,
    Form,
    Button,
    Col,
    Row,
    Dropdown,
    Modal,
    Container,
} from "react-bootstrap";
import ConfigList from "../../components/ConfigList";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { v4 as uuidv4 } from "uuid";
import { IconPlus, IconDeleteX, IconDrag } from "../../components/Icons";
import HelpText from "../../components/HelpText";
import classNames from "classnames";

const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));
const TOKEN_DELIMITER_START = "<span>";
const TOKEN_DELIMITER_END = "</span>";

const PromptEditor = () => {
    const [isModified, setIsModified] = useState(false);
    const [editMode, setEditMode] = useState(true);
    const [params, setParams] = useState(defaultSetting);
    const [configs, setConfigs] = useLocalStorage("configs", []);
    const [showHelp, setShowHelp] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const promptRef = useRef(null);

    const setParamsProxy = (value, setModified = true) => {
        setIsModified(setModified);
        setParams(value);
    };

    const [configIsCopied, setConfigIsCopied] = useClipboard("", {
        successDuration: 2000,
    });
    const [promptIsCopied, setPromptIsCopied] = useClipboard("", {
        successDuration: 2000,
    });

    const fireGaEvent = (event) => {
        if (window.dataLayer) {
            window.dataLayer.push({ event: event });
        }
    };

    const addNew = (tokensKey) => {
        const tokenName = prompt("Enter a token name");

        if (
            params[tokensKey].find((token) => {
                return token.id === tokenName;
            })
        ) {
            alert("Token name already used");
        } else if (!/^[a-zA-Z0-9_]*$/.test(tokenName)) {
            alert("Token names must only contain letters, numbers, or underscore");
        } else {
            if (tokenName) {
                updateToken(tokensKey, tokenName, "");
            }
        }
    };

    const updateConfigsList = () => {
        setConfigs(JSON.parse(window.localStorage.getItem("configs")));
    };

    const createNewConfiguration = () => {
        if (isModified) {
            const ok = window.confirm("Unsaved changes will be lost. Are you sure you want to load?");
            if (!ok) return;
        }

        fireGaEvent("New Config");
        setParamsProxy(deepCopy(blank), false);
        setEditMode(true);
    };

    const saveConfiguration = () => {
        if (!params.id) return false;

        fireGaEvent("Save");

        let storedConfigs = [];

        if (configs && Array.isArray(configs)) {
            storedConfigs = [...deepCopy(configs)];
        }

        let otherConfigs = storedConfigs.filter((config) => config.id !== params.id);

        otherConfigs.unshift({
            ...params,
            id: params.id,
            name: params.name,
            lastUpdate: new Date().getTime(),
        });
        setConfigs(otherConfigs);
        updateConfigsList();
        setIsModified(false);
    };

    const saveAsConfiguration = () => {
        fireGaEvent("Save As");
        const name = prompt("Config Name");
        if (!name) {
            return;
        }
        let storedConfigs = [];
        if (configs && Array.isArray(configs)) {
            storedConfigs = [...configs];
        }
        const newConfig = {
            ...params,
            id: uuidv4(),
            name: name,
            lastUpdate: new Date().getTime(),
        };
        storedConfigs.push(newConfig);
        setConfigs(storedConfigs);
        setParamsProxy(newConfig, false);
    };

    const toggleEditMode = () => {
        setEditMode(!editMode);
    };

    const toggleShowHelp = () => {
        setShowHelp(!showHelp);
    };

    const generatePrompt = () => {
        const detokenized = replaceTokens(params.template);
        const openChunks = detokenized.split(TOKEN_DELIMITER_START);

        const thangs = openChunks.map((openChunk, i) => {
            if (i === 0) {
                return openChunk;
            } else {
                const closeChunks = openChunk.split(TOKEN_DELIMITER_END);

                return (
                    <span key={`tc_${styles.token}_${closeChunks[0]}`}>
                        <span className={styles.token}>{closeChunks[0]}</span>
                        {closeChunks[1]}
                    </span>
                );
            }
        });
        return thangs;
    };

    const replaceTokens = (value) => {
        params.wordTokens.forEach((token) => {
            let regEx = new RegExp(`\\{${token.id}\\}`, "g");
            value = value.replaceAll(regEx, `${TOKEN_DELIMITER_START}${token.value}${TOKEN_DELIMITER_END}`);
        });
        params.weightTokens.forEach((token) => {
            let regEx = new RegExp(`\\{${token.id}\\}`, "g");
            value = value.replaceAll(regEx, `${TOKEN_DELIMITER_START}${token.value}${TOKEN_DELIMITER_END}`);
        });
        return value;
    };

    const updateToken = (tokensKey, key, value) => {
        const tokens = params[tokensKey];
        const index = tokens.findIndex((token) => token.id === key);
        if (index === -1) {
            tokens.push({ id: key, value });
        } else {
            tokens[index] = { id: key, value };
        }
        setParamsProxy({ ...params, [tokensKey]: tokens });
    };

    const updateTokenOrder = (tokensKey, dragIndex, hoverIndex) => {
        if (!editMode) {
            return;
        }
        const derTokens = deepCopy(params[tokensKey]);
        const token = derTokens.splice(dragIndex, 1)[0];
        derTokens.splice(hoverIndex, 0, token);
        setParamsProxy({ ...params, [tokensKey]: derTokens });
    };

    const removeToken = (tokensKey, key) => {
        if (!editMode) return;
        setParamsProxy({
            ...params,
            [tokensKey]: [...params[tokensKey].filter((token) => token.id !== key)],
        });
    };

    const updateTemplate = (value) => {
        setParamsProxy({ ...params, template: value });
    };

    const copyConfiguration = () => {
        fireGaEvent("Export Config");
        setConfigIsCopied(JSON.stringify(params));
    };

    const copyPrompt = () => {
        fireGaEvent("Copy Prompt");
        setPromptIsCopied(promptRef.current.innerText);
    };

    const importConfiguration = () => {
        if (isModified) {
            const ok = window.confirm("Unsaved changes will be lost. Are you sure you want to load?");
            if (!ok) return;
        }

        fireGaEvent("Import Config");
        const a = prompt("Enter JSON String");
        let settings;

        if (!a) {
            return;
        }

        try {
            settings = JSON.parse(a);
        } catch (e) {
            alert("Unable to parse JSON string.");
            return;
        }

        if (!settings.wordTokens || !settings.weightTokens) {
            alert("The confguration you are trying to import is not in the right format");
            return;
        }
        setParamsProxy(settings);
    };

    const setLoadedConfig = (id) => {
        if (isModified) {
            const ok = window.confirm("Unsaved changes will be lost. Are you sure you want to load?");
            if (!ok) return;
        }

        fireGaEvent("Load Config");
        const loadedConfig = configs.find((config) => {
            return config.id === id;
        });
        setParamsProxy(deepCopy(loadedConfig), false);
        handleClose();
    };

    const deleteConfig = (id) => {
        fireGaEvent("Delete Config");

        const ok = window.confirm("Are you sure you want to delete this config?");

        if (!ok) return;

        const c = [...configs].filter((config) => {
            return config.id !== id;
        });
        setConfigs(c);
    };

    return (
        <div className={`${styles["PromptEditor"]}`}>
            <div className="page-header">
                <div>
                    <h4>
                        Config:{" "}
                        <span className="config-name">
                            {params.name} {isModified ? "*" : ""}
                        </span>
                    </h4>
                </div>
                <div className={styles.buttons}>
                    {configIsCopied && <span className={styles.notification}>Copied</span>}
                    <Dropdown as={ButtonGroup} className="mr-2" size="sm">
                        <Dropdown.Toggle variant="light" id="dropdown-split-basic">
                          File
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                onClick={() => {
                                    createNewConfiguration();
                                }}>
                                New
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => {
                                    handleShow();
                                }}>
                                Load
                            </Dropdown.Item>

                            <Dropdown.Item
                                disabled={!params.id}
                                onClick={() => {
                                    saveConfiguration();
                                }}>
                                Save
                            </Dropdown.Item>

                            <Dropdown.Item
                                onClick={() => {
                                    saveAsConfiguration();
                                }}>
                                Save As
                            </Dropdown.Item>

                            <Dropdown.Item
                                onClick={() => {
                                    copyConfiguration();
                                }}>
                                Export
                            </Dropdown.Item>

                            <Dropdown.Item
                                onClick={() => {
                                    importConfiguration();
                                }}>
                                Import
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    {/* <ToggleButton
              size="sm"
              className="mx-2"
              variant="secondary"
              type="checkbox"
              checked={!editMode}
              onClick={() => {
                toggleEditMode();
              }}>
              Edit Mode ({editMode ? "ON" : "OFF"})
            </ToggleButton> */}
                    <ToggleButton
                        size="sm"
                        variant="light"
                        type="checkbox"
                        checked={showHelp}
                        onClick={() => {
                            toggleShowHelp();
                        }}>
                        {showHelp ? "Hide" : "Show"} Help
                    </ToggleButton>
                </div>
            </div>
            <div className="page-content">
                <Row>
                    <Col>
                        <div className="section">
                            <h5>
                                <span>Word Tokens</span>
                                {editMode && (
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip style={{ position: "fixed" }} id={`tooltip-top`}>
                                                Add Token
                                            </Tooltip>
                                        }>
                                        <div>
                                            <IconPlus
                                                onClick={() => {
                                                    addNew("wordTokens");
                                                }}
                                            />
                                        </div>
                                    </OverlayTrigger>
                                )}
                            </h5>
                            <TokenList
                                addNew={addNew}
                                inputType="text"
                                updateTokenOrder={updateTokenOrder}
                                editMode={editMode}
                                tokens={params.wordTokens}
                                tokensKey="wordTokens"
                                removeToken={removeToken}
                                updateToken={updateToken}
                            />
                        </div>
                        <div className="section">
                            <h5>
                                <span>Weight Tokens</span>
                                {editMode && (
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip style={{ position: "fixed" }} id={`tooltip-top`}>
                                                Add Token
                                            </Tooltip>
                                        }>
                                        <div>
                                            <IconPlus
                                                onClick={() => {
                                                    addNew("weightTokens");
                                                }}
                                            />
                                        </div>
                                    </OverlayTrigger>
                                )}
                            </h5>
                            <TokenList
                                addNew={addNew}
                                inputType="number"
                                updateTokenOrder={updateTokenOrder}
                                editMode={editMode}
                                tokens={params.weightTokens}
                                tokensKey="weightTokens"
                                removeToken={removeToken}
                                updateToken={updateToken}
                            />
                            <HelpText show={showHelp}>
                                Tokens can be added or removed. To add a new token, click the <IconPlus /> button. To
                                remove a currently defined token, click the <IconDeleteX /> button. Use the <IconDrag />{" "}
                                button to reorder tokens.
                            </HelpText>
                        </div>
                    </Col>
                    <Col>
                        <div className="section">
                            <h5>Prompt Template</h5>
                            <Form.Group className="mb-3" controlId="promptTemplate">
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    value={params.template}
                                    onChange={(event) => {
                                        updateTemplate(event.currentTarget.value);
                                    }}
                                />
                            </Form.Group>
                            <HelpText show={showHelp}>
                                Word and Weight Token values can be inserted into the template by surrounding the token
                                name with curly braces. eg: {"{token}"}
                            </HelpText>
                        </div>
                        <div className="section">
                            <h5>Prompt Output</h5>
                            <Card className={styles.bob}>
                                <Card.Body>
                                    <pre ref={promptRef}>{generatePrompt()}</pre>
                                </Card.Body>
                                <Card.Footer className={styles["prompt-output-footer"]}>
                                    {promptIsCopied && <span className={styles.notification}>Copied</span>}
                                    <Button
                                        size="sm"
                                        variant="outline-secondary"
                                        onClick={() => {
                                            copyPrompt();
                                        }}>
                                        Copy
                                    </Button>
                                </Card.Footer>
                            </Card>

                            <HelpText show={showHelp}>
                                This is your prompt that you can paste into MJ (or whatever AI tool you're using).
                            </HelpText>

                            <HelpText show={showHelp}>
                                Your current configuration can be saved and recalled later using "File>Save" and
                                "File>Load". You can export the configuration to your clipboard using "File>Export" and
                                import and configuration using "File>Import" and pasting in the output from "Export".
                                This will enable you to save your prompts permanently elsewhere or share them with other
                                peple.
                            </HelpText>
                        </div>
                    </Col>
                </Row>
            </div>

            <Modal centered show={show} onHide={handleClose} dialogClassName="modal-90w">
                <Modal.Header closeButton>
                    <Modal.Title>Load Configuration</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={(classNames(styles.configs), "section")}>
                        <ConfigList configs={configs} setLoadedConfig={setLoadedConfig} deleteConfig={deleteConfig} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default PromptEditor;
