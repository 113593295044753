import { useParams } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { useImageInput } from "../../hooks/useImageInput";
import axios from "axios";
import { StyleForm } from "../../components/StyleForm";
import { useFetch } from "../../hooks/useFetch";
import { Button } from "react-bootstrap";
import { ContentHeader } from "../../components/ContentHeader";
import { AuthContext } from "../../contexts/AuthContext";

import cssStyles from "./EditStyle.module.scss";

const useThisHack = (id) => {
    const [name, setName] = useState("");
    const imageInput = useImageInput();

    const updateState = (res) => {
        imageInput.setImages(res.data[0].images);
        setName(res.data[0].name);
    };

    const fetch = useFetch({
        url: `api/styles/${id}`,
        method: "get",
        dependencies: [id],
        callback: updateState,
    });

    return {
        ...imageInput,
        ...fetch,
        name,
        setName,
    };
};

const EditStyle = () => {
    const { authToken } = useContext(AuthContext);
    const { id } = useParams();
    const { images, addImage, removeImage, updateImageUrl, updateImageVersion, name, setName, loading, error } =
        useThisHack(id);

    const saveStyle = () => {
        const reqBody = {
            name,
            images,
        };

        axios({
            url: `api/styles/${id}`,
            method: "put",
            data: reqBody,
            headers: { Authorization: `Bearer ${authToken}` },
        }).then((res) => {
            console.log(res);
        });
    };

    const deleteStyle = () => {
        const confirmed = window.confirm("Are you sure you want to delete this style?");

        if (confirmed) {
            axios({
                url: `api/styles/${id}`,
                method: "delete",
                headers: { Authorization: `Bearer ${authToken}` },
            }).then((res) => {
                alert("Style deleted.");
                console.log(res)
            }).catch((err) => {
                alert(err);
            })
        }
    };

    if (loading) {
        return <p>Loading</p>;
    }

    return (
        <div className={`${cssStyles["EditStyle"]}`}>
            <div className="page-header">
                <h4>Edit Style</h4>
            </div>
            <div className="page-content">
                <StyleForm
                    images={images}
                    addImage={addImage}
                    removeImage={removeImage}
                    updateImageUrl={updateImageUrl}
                    updateImageVersion={updateImageVersion}
                    name={name}
                    setName={setName}
                    saveStyle={saveStyle}
                />
                <div className="button-bar">
                    <Button
                        variant="outline-primary"
                        onClick={(event) => {
                            deleteStyle();
                        }}>
                        Delete Style
                    </Button>
                    <Button
                        onClick={(event) => {
                            saveStyle();
                        }}>
                        Save Style
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default EditStyle;
