import { useContext } from "react";
import { Form, Button } from "react-bootstrap";
import ImageInput from "./ImageInput";
import { useFetch } from "../hooks/useFetch";
import Multiselect from "multiselect-react-dropdown";
import { ConfigContext } from "../contexts/ConfigContext";

export const ArtistForm = ({
    images,
    addImage,
    setImages,
    removeImage,
    updateImageUrl,
    updateImageVersion,
    name,
    setName,
    saveArtist,
    updateStyles,
    styles,
}) => {
    const { data, loading, error } = useFetch({
        url: "api/styles/list",
        method: "get",
        dependencies: [],
    });

    const thangs = useContext(ConfigContext);

    if (loading) return <p>Loading...</p>;

    return (
        <div>
            <Form.Group controlId="name" className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                    value={name}
                    type="text"
                    name="name"
                    placeholder="Name"
                    onChange={(e) => {
                        setName(e.currentTarget.value);
                    }}
                />
            </Form.Group>
            <Form.Group controlId="name" className="mb-3">
                <Form.Label>Styles</Form.Label>
                <Multiselect
                    options={data}
                    displayValue="name"
                    onSelect={updateStyles}
                    onRemove={updateStyles}
                    selectedValues={styles}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>Images</Form.Label>
                {images.map((image, index) => {
                    return (
                        <ImageInput
                            image={image}
                            index={index}
                            updateImageUrl={updateImageUrl}
                            updateImageVersion={updateImageVersion}
                            removeImage={removeImage}
                        />
                    );
                })}
            </Form.Group>
            <div className="mb-4">
                <Button
                    size="sm"
                    variant="secondary"
                    onClick={(event) => {
                        addImage();
                    }}>
                    Add Image
                </Button>
            </div>
        </div>
    );
};
