import { useParams } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { Button } from "react-bootstrap";
import { useImageInput } from "../../hooks/useImageInput";
import axios from "axios";
import { ArtistForm } from "../../components/ArtistForm";
import { useFetch } from "../../hooks/useFetch";
import { ContentHeader } from "../../components/ContentHeader";
import { AuthContext } from "../../contexts/AuthContext";
import cssStyles from "./EditArtist.module.scss";

const useThisHack = (id) => {
    const [name, setName] = useState("");
    const imageInput = useImageInput();
    const [styles, setStyles] = useState([]);
    const updateStyles = (selectedStyles) => {
        setStyles(selectedStyles);
    };
    const updateState = (res) => {
        imageInput.setImages(res.data[0].images);
        setName(res.data[0].name);
        setStyles(res.data[0].styles);
    };

    const fetch = useFetch({
        url: `api/artists/${id}`,
        method: "get",
        dependencies: [id],
        callback: updateState,
    });

    return {
        ...imageInput,
        ...fetch,
        name,
        setName,
        styles,
        updateStyles,
        updateState,
    };
};

const EditArtist = () => {
    const { id } = useParams();
    const { authToken } = useContext(AuthContext);

    const {
        images,
        addImage,
        setImages,
        removeImage,
        updateImageUrl,
        updateImageVersion,
        name,
        setName,
        loading,
        error,
        styles,
        updateStyles,
        updateState,
    } = useThisHack(id);

    const deleteArtist = () => {
        const confirmed = window.confirm("Are you sure you want to delete this artist?");

        if (confirmed) {
            axios({
                url: `api/artists/${id}`,
                method: "delete",
                headers: { Authorization: `Bearer ${authToken}` },
            }).then((res) => {
                alert("Artist deleted.");
                console.log(res)
            }).catch((err) => {
                alert(err);
            })
        }
    };

    const saveArtist = () => {
        const styleIds = styles.map((style) => style.id);
        const reqBody = {
            name,
            images,
            styles: styleIds,
        };
        axios({
            url: `api/artists/${id}`,
            method: "put",
            data: reqBody,
            headers: { Authorization: `Bearer ${authToken}` },
        })
            .then((res) => {
                updateState(res);
                alert("Artist has been saved");
            })
            .catch((e) => {
                alert("There was an error saving the artist");
                console.log(e);
            });
    };

    if (loading) {
        return <p>Loading</p>;
    }

    return (
        <div className={`${cssStyles["EditArtist"]}`}>
            <div className="page-header">
                <h4>Edit Artist</h4>
            </div>
            <div className="page-content">
                <ArtistForm
                    setImages={setImages}
                    images={images}
                    addImage={addImage}
                    styles={styles}
                    updateStyles={updateStyles}
                    removeImage={removeImage}
                    updateImageUrl={updateImageUrl}
                    updateImageVersion={updateImageVersion}
                    name={name}
                    setName={setName}
                />

                <div className="button-bar">
                    <Button
                        variant="outline-primary"
                        onClick={(event) => {
                            deleteArtist();
                        }}>
                        Delete Artist
                    </Button>

                    <Button
                        onClick={(event) => {
                            saveArtist();
                        }}>
                        Save Artist
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default EditArtist;
