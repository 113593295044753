import { useEffect, useState } from "react";
import axios from "axios";

export const useFetch = ({ url, options, body, method, dependencies, callback = () => {} }) => {
  const [fetchState, setFetchState] = useState({
    loading: true,
    error: "",
    data: [],
  });
  const updateData = (partialData) => setFetchState(Object.assign(Object.assign({}, fetchState), partialData));
  const load = async () => {
    updateData({
      loading: true,
      data: undefined,
      error: undefined,
    });
    try {
      const res = await axios({
        method,
        url,
        data: body,
      });
      updateData({
        loading: false,
        data: res.data,
        error: undefined,
      });
      callback(res);
    } catch (error) {
      updateData({
        loading: false,
        error,
      });
    }
  };
  useEffect(() => {
    load();
  }, dependencies);
  return {
    data: fetchState.data,
    error: fetchState.error,
    loading: fetchState.loading,
  };
};
