import React, { useState, useEffect } from "react";
import styles from "./Home.module.scss";
import Logo from "../../images/bg.png";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { Row, Col, Card, Button, Alert } from "react-bootstrap";

import { BiUser } from "react-icons/bi";
import { BiCode } from "react-icons/bi";
import { BiPaint } from "react-icons/bi";

import { ReactComponent as IconUser } from "../../images/user.svg";
import { ReactComponent as IconCode } from "../../images/code.svg";
import { ReactComponent as IconPaint } from "../../images/paint.svg";

const Home = (props) => {
    return (
        <div className={`${styles["Home"]}`}>
            <div className={styles.cards}>
                <Card className={styles.card}>
                    <div className={styles.cardImg}>
                        <IconCode />
                    </div>
                    <Card.Body>
                        <Card.Title>Prompt Editor</Card.Title>
                        <Card.Text className={styles.cardText}>
                            Use the prompt editor to make a prompt template. Save and recall old prompts!
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer className={styles.footer}>
                        <Button size="sm" variant="dark" as={NavLink} to="/prompt">
                            Go!
                        </Button>
                    </Card.Footer>
                </Card>

                <Card className={styles.card}>
                    <div className={styles.cardImg}>
                        <IconUser />
                    </div>
                    <Card.Body>
                        <Card.Title>Artist References</Card.Title>
                        <Card.Text className={styles.cardText}>
                            View various artists and how their style might have an effect on your images
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer className={styles.footer}>
                        <Button size="sm" variant="dark" as={NavLink} to="/artists">
                            Go!
                        </Button>
                    </Card.Footer>
                </Card>

                <Card className={styles.card}>
                    <div className={styles.cardImg}>
                        <IconPaint />
                    </div>
                    <Card.Body>
                        <Card.Title>Style References</Card.Title>
                        <Card.Text className={styles.cardText}>
                            View various artistic styles and how it might have an effect on your images
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer className={styles.footer}>
                        <Button size="sm" variant="dark" as={NavLink} to="/styles">
                            Go!
                        </Button>
                    </Card.Footer>
                </Card>
            </div>
            <Alert variant="poop" className={styles.alert}>
                PromptLab is in beta. Be gentle.
            </Alert>
        </div>
    );
};

export default Home;
