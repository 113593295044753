import React from "react";
import { useContext, useState, useEffect, version } from "react";
import { NavLink } from "react-router-dom";
import { ConfigContext } from "../../contexts/ConfigContext";
import { Form, Button, Modal } from "react-bootstrap";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import styles from "./ImagesTable.module.scss";

const DEFAULT_IMAGE_SIZE = 60;

const ImagesTable = ({ data, objType, objName, showEdit }) => {
    const versions = useContext(ConfigContext);
   
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState("");
    const [size, setSize] = useLocalStorage("size", DEFAULT_IMAGE_SIZE);
    const [filters, setFilters] = useLocalStorage("filters", () => {
        const defaultShown = [];
        let state = {};
        versions.map((version) => {
            if (defaultShown.indexOf(version.name) !== -1) {
                state[version.id] = true;
            }
        });
        return state;
    });

    const reset = () => {
        setSize(DEFAULT_IMAGE_SIZE);
        setFilters({})
    }

    const getImage = (version, row) => {
        const images = row.images.filter((image) => {
            return image.version_id === version.id;
        });

        if (images.length) {
            return images.map((image) => (
                <div
                    key={`P_${image.id}`}
                    className={styles["ref-image-parent"]}
                    onClick={() => {
                        const v = versions.find((v) => v.id === version.id);
                        setModalData({
                            url: image.url,
                            name: row.name,
                            version: v.name,
                        });
                        setShowModal(true);
                    }}>
                    <img
                        style={{ maxHeight: `${size}px` }}
                        
                        className={styles["ref-image"]}
                        src={image.url}
                    />
                </div>
            ));
        } else {
            return <div className={styles.ximage}>&nbsp;</div>
        }
    };

    const setFilter = (e) => {
        const previousState = { ...filters };
        if (e.currentTarget.checked) {
            delete previousState[e.currentTarget.name];
        } else {
            previousState[e.currentTarget.name] = true;
        }
        setFilters(previousState);
    };

    return (
        <div className={`${styles["ImagesTable"]}`}>
            <div className={styles["images-table-settings"]}>
                <div className={styles["image-size-slider"]}>
                    <Form.Label>Image Size</Form.Label>
                    <Slider
                        onChange={(e) => {
                            setSize(e);
                        }}
                        min={20}
                        max={600}
                        defaultValue={size}
                        value={size}
                    />
                </div>
                <div className={styles["image-filters"]}>
                    <Form.Label>Show Versions</Form.Label>
                    <div className={styles["image-filters-checks"]}>
                        {versions.map((version) => {
                            return (
                                <span key={`P_${version.id}`}>
                                    <Form.Check
                                        name={`${version.id}`}
                                        checked={!filters[version.id]}
                                        onChange={(e) => {
                                            setFilter(e);
                                        }}
                                        key={version.id}
                                        inline
                                        label={version.name}
                                        type="checkbox"
                                        id={`inline-${version.id}-1`}
                                    />
                                </span>
                            );
                        })}
                    </div>
                </div>
                <div className={styles["reset-settngs"]}>
                    <Form.Label>Settings</Form.Label>
                    <br />
                    <Button size="sm" onClick={reset}>Reset</Button>
                </div>
            </div>

            <table>
                <thead>
                    <tr>
                        <th>{objName}</th>
                        {versions.map((version) => {
                            return !filters[version.id] && <th key={`thang_${version.id}`}>{version.name}</th>;
                        })}
                        {showEdit && <th>Actions</th>}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row) => {
                        return (
                            <tr key={`row_${row.id}`}>
                                <td>{row.name}</td>
                                {versions.map((version) => {
                                    return (
                                        !filters[version.id] && (
                                            <td key={`thing_${version.id}`}>{getImage(version, row)}</td>
                                        )
                                    );
                                })}
                                <td>
                                    {showEdit && (
                                        <Button variant="secondary" size="sm" as={NavLink} to={`/${objType}/${row.id}`}>
                                            Edit
                                        </Button>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <Modal dialogClassName="modal-90w" centered show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {modalData.name} &gt; {modalData.version}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <img style={{ width: "100%" }} src={modalData.url} />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ImagesTable;
