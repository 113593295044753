import React, { useContext } from "react";
import styles from "./ViewArtists.module.scss";
import { useFetch } from "../../hooks/useFetch";
import ImagesTable from "../../components/ImagesTable";
import { AuthContext } from "../../contexts/AuthContext";
const ViewArtists = () => {
    const { user } = useContext(AuthContext);
    const { data, error, loading } = useFetch({
        url: "api/artists",
        method: "get",
        dependencies: [],
    });

    return (
        <div className={`${styles["ViewArtists"]}`}>
            <div className="page-header">
                <h4>Artist References</h4>
            </div>
            <div className="page-content">
                {loading ? (
                    <p>Loading</p>
                ) : (
                    <ImagesTable data={data} objType="artists" objName="Artist" showEdit={user.USER_ROLE_ADMIN === 1} />
                )}
            </div>
        </div>
    );
};

export default ViewArtists;
