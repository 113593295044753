import { Form, Button, Row, Col, Image, Card } from "react-bootstrap";
import ImageInput from "./ImageInput";

export const StyleForm = ({
    images,
    addImage,
    removeImage,
    updateImageUrl,
    updateImageVersion,
    name,
    setName,
    saveStyle,
}) => {
    return (
        <div className="StyleEditor">
            <Form.Group controlId="name" className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                    value={name}
                    type="text"
                    name="name"
                    placeholder="Name"
                    onChange={(e) => {
                        setName(e.currentTarget.value);
                    }}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>Images</Form.Label>
                {images.map((image, index) => {
                    return (
                        <ImageInput
                            image={image}
                            index={index}
                            updateImageUrl={updateImageUrl}
                            updateImageVersion={updateImageVersion}
                            removeImage={removeImage}
                        />
                    );
                })}
            </Form.Group>
            <div className="mb-4">
                <Button
                    size="sm"
                    variant="secondary"
                    onClick={(event) => {
                        addImage();
                    }}>
                    Add Image
                </Button>
            </div>
        </div>
    );
};
