import React from "react";
import styles from "./Footer.module.scss";

const Footer = (props) => {
  const appVersion = process.env.REACT_APP_VERSION;
  return (
    <div className={`${styles["Footer"]}`}>
      ©2022 PromptLab.net | v{appVersion} | <a href="http://www.discordapp.com/users/1007894634015502366">@spreeok</a>
    </div>
  );
};

export default Footer;
