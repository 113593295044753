import React from "react";
import styles from "./TokenList.module.scss";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Token from "../Token";

const TokenList = ({ addNew, inputType, editMode, tokens, tokensKey, updateToken, removeToken, updateTokenOrder }) => {
  const handleRemove = (key) => {
    removeToken(tokensKey, key);
  };

  const moveCard = (dragIndex, hoverIndex) => {
    updateTokenOrder(tokensKey, dragIndex, hoverIndex);
  };

  const createInputs = (tokens) => {
    return tokens.map((token, i) => (
      <Token
        inputType={inputType}
        key={token.id}
        id={`PPP_${token.id}`}
        index={i}
        moveCard={moveCard}
        updateToken={updateToken}
        tokensKey={tokensKey}
        token={token}
        editMode={editMode}
        handleRemove={handleRemove}
      />
    ));
  };

  return (
    <div className={`${styles["TokenList"]}`}>
      <DndProvider backend={HTML5Backend}>
        <div className={styles.list}>{createInputs(tokens)}</div>
      </DndProvider>
    </div>
  );
};

export default TokenList;
