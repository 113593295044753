export const defaultSetting = {
    id: "",
    lastUpdate: 45235254,
    name: "Default",
    type: "default",
    
	wordTokens: [
		{
            id: "animal",
            value: "dog"
        },
		{
            id: "place",
            value: "city"
        },
        {
            id: "style",
            value: "Constructivism"
        }
    ],
	weightTokens: [
		{
            id: "w1",
            value: 1
        },
		{
            id: "w2",
            value: 5
        },
        {
            id: "w3",
            value: 30
        }
    ],
	template: "a large {animal} standing in a {place}::{w1} in the style of {style}"
};