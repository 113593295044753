import React, { useContext, useState } from "react";
import styles from "./Login.module.scss";
import { Form, Button, Col, Row } from "react-bootstrap";
import axios from "axios";
import { AuthContext } from "../../contexts/AuthContext";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const Login = (props) => {
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);

    const [cookies, setCookie] = useCookies();

    const login = async () => {
        const reqBody = {
            name,
            password,
        };

        axios
            .post("api/login", reqBody)
            .then((res) => {
                authContext.setAuthToken(res.data.token);
                authContext.setUser(res.data.user);
                setCookie("auth-token", res.data.token, { maxAge: 1800 });
                navigate("/");
            })
            .catch((res) => {
                alert("User not found...");
            });
    };

    return (
        <div className={`${styles["Login"]}`}>
            <div className="page-header">
                <h4>Login</h4>
            </div>
            <div className="page-content">
                <div className={styles.form}>
                    <Form.Group controlId="user" className="mb-4">
                        <Form.Control
                            type="text"
                            placeholder="username"
                            value={name}
                            onChange={(e) => {
                                setName(e.currentTarget.value);
                            }}
                        />
                    </Form.Group>
                    <Form.Group controlId="password" className="mb-4">
                        <Form.Control
                            type="password"
                            placeholder="password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.currentTarget.value);
                            }}
                        />
                    </Form.Group>
                    <Button onClick={login}>Login</Button>
                </div>
            </div>
        </div>
    );
};

export default Login;
