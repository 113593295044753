import { useContext } from "react";
import { Form, Button } from "react-bootstrap";
import { ConfigContext } from "../../contexts/ConfigContext";
import styles from "./ImageInput.module.scss";

const ImageInput = ({ image, index, updateImageUrl, updateImageVersion, removeImage }) => {
    const data = useContext(ConfigContext);

    return (
        <div className={styles["img-input-container"]} key={`image_${index}`}>
            <div className={styles["img-input-inputs"]}>
                <div className={styles["img-input-form"]}>
                    <Form.Group controlId={`version_${index}`}>
                        <Form.Label>Version</Form.Label>
                        <Form.Select
                            name={`version_${index}`}
                            placeholder={`Version ${index}`}
                            onChange={(event) => {
                                updateImageVersion(event.currentTarget.value, index);
                            }}
                            value={image.version}>
                            {data.map((version, index) => {
                                return <option value={version.id}>{version.name}</option>;
                            })}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group controlId={`image_${index}`} className={styles["image-name"]}>
                        <Form.Label>URL</Form.Label>
                        <Form.Control
                            type="text"
                            name={`image_${index}`}
                            placeholder={`Image ${index}`}
                            onChange={(event) => {
                                updateImageUrl(event.currentTarget.value, index);
                            }}
                            value={image.url}
                        />
                    </Form.Group>
                </div>
                <div className={styles["img-input-buttons"]}>
                    <Button
                        size="sm"
                        variant="outline-secondary"
                        onClick={(event) => {
                            if (window.confirm("Are you sure you want to delete this image?")) removeImage(index);
                        }}>
                        Delete Image
                    </Button>
                </div>
            </div>
            <div className={styles["reference-image"]}>
                <img src={image.url} />
            </div>
        </div>
    );
};

export default ImageInput;
