import React from "react";
import styles from "./HelpText.module.scss";
import { IconHelp } from "../Icons";

const HelpText = ({ children, show }) => {

    if(!show) {
        return <></>
    }

	return (
		<div className={`${styles["HelpText"]}`}>
			      <div className={styles.icon}>
        <IconHelp />
      </div>
      <aside>{children}</aside>
		</div>
	)

}

export default HelpText;