import { useState, useContext } from "react";
import { Button } from "react-bootstrap";
import { useImageInput } from "../../hooks/useImageInput";
import axios from "axios";
import { StyleForm } from "../../components/StyleForm";
import { ContentHeader } from "../../components/ContentHeader";
import { ConfigContext } from "../../contexts/ConfigContext";
import { AuthContext } from "../../contexts/AuthContext";
import cssStyles from "./CreateStyle.module.scss";

const CreateStyle = () => {
    const thangs = useContext(ConfigContext);
    const { authToken } = useContext(AuthContext);
    const versionsSorted = thangs.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    });

    const { images, addImage, removeImage, updateImageUrl, updateImageVersion } = useImageInput(() => {
        return versionsSorted.map((version) => {
            return {
                url: "",
                version: version.id,
            };
        });
    });
    const [name, setName] = useState("");

    const saveStyle = () => {
        const reqBody = {
            name,
            images,
        };

        axios({
            url: "api/styles",
            method: "post",
            data: reqBody,
            headers: { Authorization: `Bearer ${authToken}` },
        }).then((res) => {
            console.log(res);
        });
    };

    return (
        <div className={`${cssStyles["CreateStyle"]}`}>
            <div className="page-header">
                <h4>Create Style</h4>
            </div>
            <div className="page-content">
                <StyleForm
                    images={images}
                    addImage={addImage}
                    removeImage={removeImage}
                    updateImageUrl={updateImageUrl}
                    updateImageVersion={updateImageVersion}
                    name={name}
                    setName={setName}
                    saveStyle={saveStyle}
                />
                <div className="button-bar">
                    <Button
                        onClick={(event) => {
                            saveStyle();
                        }}>
                        Save Style
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default CreateStyle;
