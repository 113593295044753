import { useState } from "react";

export const useImageInput = (imgs) => {

  let initImages = [];

  if(typeof(imgs) === "function") {
    initImages = imgs();
  } else {
    if(imgs) initImages = imgs;
  }

  const [images, setImages] = useState(initImages);

  const removeImage = (index) => {
    const newImageState = [...images];
    newImageState.splice(index, 1);
    setImages(newImageState);
  };

  const addImage = () => {
    const newImageState = [...images];
    newImageState.push({
      url: "",
      version: 8,
    });
    setImages(newImageState);
  };

  const updateImageUrl = (value, index) => {
    const newImagesState = [...images];
    newImagesState[index].url = value;
    setImages(newImagesState);
  };

  const updateImageVersion = (value, index) => {
    const newImagesState = [...images];
    newImagesState[index].version = value;
    setImages(newImagesState);
  };

  return {
    images,
    setImages,
    addImage,
    removeImage,
    updateImageUrl,
    updateImageVersion,
  };
};
